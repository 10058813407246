<template>
  <v-dialog max-width="600px"
            :value="value"
            @input="$emit('input', value)"
            @click:outside="cancel">
    <v-card>
      <v-card-title class="primary white--text">{{ title }}</v-card-title>
      <v-card-text>
        <form-section title="Información del local"/>
        <v-form ref="form" v-model="validation.valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="office.name"
                :rules="validation.rules.name"
                label="Nombre"
                outlined
                placeholder="Nombre de la oficina/local"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="office.address"
                :rules="validation.rules.address"
                label="Dirección"
                outlined
                placeholder="Dirección de la oficina/local"/>
            </v-col>
            <v-col cols="12">
              <!--  Ciudad      -->
              <v-autocomplete
                v-model="office.location"
                :items="Object.keys(dictionary.cities).map(k => dictionary.cities[k])"
                :loading="isLoadingCities"
                :rules="validation.rules.location"
                :search-input.sync="citySearch"
                clearable
                hide-no-data
                item-text="description"
                item-value="slug"
                label="Ciudad"
                outlined
                placeholder="Ingrese el nombre de la ciudad"
                return-object
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="cancel">
          Cancelar
        </v-btn>
        <v-spacer/>
        <v-btn color="primary" @click="save">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormSection from "@/components/widgets/Section";
import {mapActions, mapState} from "vuex";

export default {
  name: "OfficeDialog",
  components: { FormSection },
  props: {
    value: { type: Boolean, required: true, default: null },
    office: { type: Object,  required: true, default: null },
  },
  data: () => ({
    validation: {
      valid: false,
      rules: {
        name: [(v) => !!v || "Ingrese un nombre para la oficina"],
        address: [(v) => !!v || "Ingrese una dirección para la oficina"],
        location: [(v) => !!v || "Ingrese la ciudad de la oficina"],
      },
    },
    citySearch: null,
    isLoadingCities: false,
  }),
  computed: {
    ...mapState('library', ['dictionary']),
    title() {
      return this.office.id ? 'Editar el local' : 'Nuevo local'
    },
  },
  watch: {
    citySearch(val) {
      if (!val) return;

      val &&
      (typeof this.office.location === "undefined" ||
        this.office.location === null ||
        (this.office.location &&
          val !== this.office.location.description)) &&
      this.fetchCitiesDebounced(val);
    },
  },
  methods: {
    ...mapActions("library", ['fetchCities']),
    cancel() {
      this.$refs.form.resetValidation()
      this.$emit('input', false)
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$refs.form.resetValidation()
        this.$emit('save', this.office)
      }
    },
    fetchCitiesDebounced(val) {
      clearTimeout(this._searchTimerId);

      this._searchTimerId = setTimeout(
        () => {
          this.isLoadingCities = true;
          this.fetchCities({ query: val })
            .finally(() => (this.isLoadingCities = false));
        },
        500
      ); /* 500ms throttle */
    },
  }
}
</script>

<style scoped>

</style>