import { render, staticRenderFns } from "./ClientCard.vue?vue&type=template&id=33f9dc90&scoped=true&"
import script from "./ClientCard.vue?vue&type=script&lang=js&"
export * from "./ClientCard.vue?vue&type=script&lang=js&"
import style0 from "./ClientCard.vue?vue&type=style&index=0&id=33f9dc90&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33f9dc90",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VChip,VDivider,VIcon,VList,VListItemSubtitle,VTooltip})
