<template>
  <v-list-item class="pa-0">
    <v-list-item-content>
      <slot :title="title" name="title">
        <!-- Fallback content -->
        <v-list-item-title class="text-body-2 font-weight-bold">{{ title }}</v-list-item-title>
      </slot>
      <slot :description="description" name="description">
        <!-- Fallback content -->
        <v-list-item-subtitle v-if="description" class="text-right">{{ description }}</v-list-item-subtitle>
        <v-list-item-subtitle v-else class="text-right">N/A</v-list-item-subtitle>
      </slot>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "ClientCardListItem",
  props: {
    description: { required: true, default: null },
    title: { type: String, required: true, default: null }
  },
}
</script>

<style scoped>

</style>