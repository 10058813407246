<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="3" md="4">
        <client-card :client="client" @edit="editClient"/>
      </v-col>
      <v-col cols="12" lg="9" md="8">
        <client-offices
          :client-id="clientId"
          :offices="client.offices"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";
import ClientCard from "@/components/client/ClientCard";
import ClientOffices from "@/components/client/ClientOffices";

export default {
  name: "ClientView",
  components: {ClientCard, ClientOffices},
  data: () => ({
    clientId: null,
    client: {
      offices: [],
      supervisor: null
    }
  }),
  created() {
    this.clientId = this.$route.params['id'];
    this.fetchClient(this.clientId)
      .then(client => this.client = client)
      .catch(() => {
      })
  },
  methods: {
    ...mapActions('client', ['fetchClient']),
    editClient() {
      this.$router.push({name: 'admin.client.edit', params: {id: this.$route.params['id']}}, () => {
      })
    },
  },
}
</script>

<style scoped>

</style>