var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4",attrs:{"elevation":"3"}},[_c('div',{staticClass:"d-flex flex-column align-center mb-3 pt-3"},[_c('v-icon',{staticClass:"client-img img-circle",attrs:{"size":"100"}},[_vm._v("mdi-office-building")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text-h6 text-center text-truncate client-username"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.client.name)+" ")])]}}])},[_vm._v(" "+_vm._s(_vm.client.name)+" ")]),_c('p',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pa-1",attrs:{"color":_vm.client.emailVerified ? 'green accent-5' : 'error'}},'v-icon',attrs,false),on),[_vm._v(" mdi-email ")])]}}])},[_vm._v(" "+_vm._s(_vm.client.emailVerified ? 'El correo electrónico de contacto está verificado' : 'El cliente no tiene un correo verificado')+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pa-1",attrs:{"color":_vm.client.phoneVerified ? 'green accent-5' : 'error'}},'v-icon',attrs,false),on),[_vm._v(" mdi-cellphone-iphone ")])]}}])},[_vm._v(" "+_vm._s(_vm.client.phoneVerified ? 'El numero de contacto está verificado' : 'El cliente no tiene un teléfono verificado')+" ")])],1),_c('if-auth',{scopedSlots:_vm._u([{key:"allowed",fn:function(ref){
var admin = ref.admin;
return [(admin)?_c('v-btn',{attrs:{"block":"","color":"primary","depressed":""},on:{"click":function($event){return _vm.$emit('edit')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-square-edit-outline ")]),_vm._v(" Editar ")],1):_vm._e()]}}])})],1),_c('v-divider',{staticClass:"mb-3"}),_c('div',{staticClass:"d-flex flex-column"},[_c('v-list',{attrs:{"subheader":"","two-line":""}},[_c('client-card-list-item',{attrs:{"description":_vm.client.identification,"title":"Identificación"}}),_c('v-divider',{staticClass:"mb-3"}),_c('client-card-list-item',{attrs:{"description":_vm.client.email,"title":"Correo electrónico"}}),_c('v-divider',{staticClass:"mb-3"}),_c('client-card-list-item',{attrs:{"description":_vm.client.phone,"title":"Teléfono"}}),_c('v-divider',{staticClass:"mb-3"}),_c('client-card-list-item',{attrs:{"description":_vm.client.supervisor ? _vm.client.supervisor.name : null,"title":"Supervisor"},scopedSlots:_vm._u([{key:"description",fn:function(ref){
var description = ref.description;
return [_c('v-list-item-subtitle',{staticClass:"text-right"},[(description)?_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(description))]):_c('span',[_vm._v("N/A")])],1)]}}])}),_c('v-divider',{staticClass:"mb-3"}),_c('client-card-list-item',{attrs:{"description":_vm.client.description,"title":"Detalles"},scopedSlots:_vm._u([{key:"description",fn:function(ref){
var description = ref.description;
return [(!description)?_c('v-list-item-subtitle',{staticClass:"text-right"},[_vm._v("N/A")]):_c('p',{staticClass:"pt-3"},[_vm._v(" "+_vm._s(description))])]}}])}),_c('v-divider',{staticClass:"mb-3"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }