<template>
  <v-card elevation="3">
    <v-toolbar class="primary white--text" flat>
      Locales del cliente
      <v-spacer />
      <v-btn icon @click="showCreateOfficeDialog">
        <v-icon color="white">mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <office-dialog
        v-model="officeDialog"
        :office="office"
        @save="saveOffice"
      />
      <confirmation-dialog
        v-model="deleteDialog"
        text="¿Desea eliminar la oficina del cliente?"
        @confirmed="confirmed"
        @input="closeDeleteDialog"
      />
      <v-data-table
        :headers="headers"
        :items="offices"
        :search="search"
        item-key="name"
        class="elevation-1"
        disable-sort
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            :prepend-inner-icon="'mdi-filter-variant'"
            append-icon="mdi-magnify"
            clearable
            hide-details
            placeholder="Aa..."
            solo
            text
          ></v-text-field>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            cclass="ma-1"
            color="light-blue darken-3"
            @click="editOffice(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon class="ma-1" color="red accent" @click="deleteOffice(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import EventBus from "@/util/eventbus";

import OfficeDialog from "@/components/forms/OfficeDialog";
import ConfirmationDialog from "@/components/dialog/ConfirmationDialog";

export default {
  name: "ClientOffices",
  components: { OfficeDialog, ConfirmationDialog },
  props: {
    offices: { type: Array, required: true },
    clientId: { type: String, required: true, default: null },
  },
  data: () => ({
    options: {},
    search: "",
    officeDialog: false,
    deleteDialog: false,
    office: {
      location: null,
    },
    defaultOffice: {
      location: null,
    },
    headers: [
      {
        text: "Ciudad",
        value: "location.description",
        width: 200,
      },
      {
        text: "Nombre",
        value: "name",
      },
      {
        text: "Dirección",
        value: "address",
      },
      {
        text: "Acciones",
        value: "actions",
        width: 100,
      },
    ],
  }),
  watch: {
    officeDialog(val) {
      val || this.closeOfficeDialog();
    },
  },
  methods: {
    ...mapActions("client", {
      createOffice: "createOffice",
      editOff: "editOffice",
      delOffice: "deleteOffice",
    }),
    ...mapMutations("library", ["setCities"]),
    saveOffice(item) {
      if (item.id) {
        this.editOff({ clientId: this.clientId, data: item }).then(() => {
          const index = this.offices.findIndex(
            (office) => office.id === item.id
          );
          this.offices.splice(index, 1, item);
          this.closeOfficeDialog();
          EventBus.$emit("SHOW_SNACKBAR", {
            text: "Los datos han sido guardados",
            color: "success",
          });
        });
      } else {
        this.createOffice({ clientId: this.clientId, data: item }).then(
          (office) => {
            this.closeOfficeDialog();
            this.offices.push(office);
            EventBus.$emit("SHOW_SNACKBAR", {
              text: "Se creó el nuevo local para el cliente",
              color: "success",
            });
          }
        );
      }
    },
    confirmed() {
      this.delOffice({ clientId: this.clientId, data: this.office }).then(
        () => {
          const index = this.offices.findIndex(
            (office) => office.id === this.office.id
          );
          this.offices.splice(index, 1);
          EventBus.$emit("SHOW_SNACKBAR", {
            text: "Se eliminó el local",
            color: "success",
          });
          this.closeDeleteDialog();
        }
      );
    },
    editOffice(item) {
      this.office = Object.assign({}, item);
      this.officeDialog = true;
    },
    deleteOffice(item) {
      this.office = item;
      this.deleteDialog = true;
    },
    showCreateOfficeDialog() {
      this.officeDialog = true;
    },
    closeOfficeDialog() {
      this.officeDialog = false;
      this.office = Object.assign({}, this.defaultOffice);
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
      this.office = Object.assign({}, this.defaultOffice);
    },
  },
};
</script>

<style scoped>
</style>