<template>
  <v-card class="pa-4" elevation="3">
    <div class="d-flex flex-column align-center mb-3 pt-3">
      <v-icon class="client-img img-circle" size="100">mdi-office-building</v-icon>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" class="text-h6 text-center text-truncate client-username" v-on="on"> 
            {{ client.name }}
          </span>
        </template>
        {{ client.name }}
      </v-tooltip>
      <p>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" :color="client.emailVerified ? 'green accent-5' : 'error'" class="pa-1" v-on="on">
              mdi-email
            </v-icon>
          </template>
          {{
            client.emailVerified ? 'El correo electrónico de contacto está verificado' : 'El cliente no tiene un correo verificado'
          }}
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" :color="client.phoneVerified ? 'green accent-5' : 'error'" class="pa-1" v-on="on">
              mdi-cellphone-iphone
            </v-icon>
          </template>
          {{
            client.phoneVerified ? 'El numero de contacto está verificado' : 'El cliente no tiene un teléfono verificado'
          }}
        </v-tooltip>
      </p>
      <if-auth>
        <template v-slot:allowed="{admin}">
          <v-btn v-if="admin" block color="primary" depressed @click="$emit('edit')">
            <v-icon left>
              mdi-square-edit-outline
            </v-icon>
            Editar
          </v-btn>
        </template>
      </if-auth>
    </div>
    <v-divider class="mb-3"/>
    <div class="d-flex flex-column">
      <v-list subheader two-line>
        <client-card-list-item :description="client.identification" title="Identificación"/>
        <v-divider class="mb-3"/>
        <client-card-list-item :description="client.email" title="Correo electrónico"/>
        <v-divider class="mb-3"/>
        <client-card-list-item :description="client.phone" title="Teléfono"/>
        <v-divider class="mb-3"/>
        <client-card-list-item
          :description="client.supervisor ? client.supervisor.name : null"
          title="Supervisor"
        >
          <template v-slot:description="{description}">
            <v-list-item-subtitle class="text-right">
              <v-chip v-if="description" color="primary">{{ description }}</v-chip>
              <span v-else>N/A</span>
            </v-list-item-subtitle>
          </template>
        </client-card-list-item>
        <v-divider class="mb-3"/>
        <client-card-list-item :description="client.description" title="Detalles">
          <template v-slot:description="{description}">
            <v-list-item-subtitle v-if="!description" class="text-right">N/A</v-list-item-subtitle>
            <p v-else class="pt-3"> {{ description }}</p>
          </template>
        </client-card-list-item>
        <v-divider class="mb-3"/>
      </v-list>
    </div>
  </v-card>
</template>

<script>
import ClientCardListItem from "@/components/widgets/list/ClientCardListItem";
import IfAuth from "@/components/auth/IfAuth";

export default {
  name: "ClientCard",
  components: { IfAuth, ClientCardListItem },
  props: {
    client: { type: Object, required: true },
  },
  methods: {}
}
</script>

<style lang="sass" scoped>
.img-circle
  border-radius: 50%

.client-img
  border: 3px solid #adb5bd
  margin: 0 auto
  padding: 3px

.client-username
  margin-top: 5px
  width: 100%
</style>