<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col cols="12" md="8" sm="10">
        <v-card v-if="loading" elevation="3">
          <v-card-title></v-card-title>
          <v-card-text>
            <v-sheet>
              <v-skeleton-loader
                type="card-heading, divider, list-item-three-line, list-item-three-line, divider, list-item-three-line, divider, list-item-three-line, actions"
              ></v-skeleton-loader>
            </v-sheet>
          </v-card-text>
        </v-card>
        <client-form v-else
                     v-model="formData"
                     :title="`Editar cliente ${formData.identification}`"
                     @save="handleEditClient"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ClientForm from "@/components/forms/ClientForm";
import EventBus from "@/util/eventbus";
import {mapActions} from "vuex";

export default {
  name: "CreateClient",
  components: { ClientForm },
  data: () => ({
    loading: true,
    formData: {
      identification: null,
      name: null,
      phone: null,
      email: null,
      supervisor: null,
      description: null,
    }
  }),
  created() {
    const id = this.$route.params['id']
    this.$store.commit('LOADER', true)
    this.fetchClient(id)
      .then(client => {
        this.formData = Object.assign({}, client)
      })
      .finally(() => {
        this.$store.commit('LOADER', false)
        this.loading = false
      });
  },
  methods: {
    ...mapActions("client", ['createEmployee', 'editEmployee', 'fetchClient']),
    handleEditClient(data) {
      this.$store.commit('LOADER', true)
      const clientId = this.$route.params['id']
      this.editEmployee({ id: clientId, employee: data })
        .then(() => {
          this.$router.go(-1)
          EventBus.$emit('SHOW_SNACKBAR', {
            text: 'Los cambios han sido guardados',
            color: 'success'
          })
        })
        .finally(() => {
          this.$store.commit('LOADER', false)
        });
    },
  }
}
</script>

<style scoped>

</style>