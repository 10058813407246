<template>
  <v-card>
    <v-card-title class="primary white--text">{{ title }}</v-card-title>
    <v-card-text>
      <form-section title="Información del cliente"/>
      <v-form ref="form" v-model="validation.valid" lazy-validation>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="value.identification"
              :rules="validation.rules.identification"
              label="Ruc/Cédula"
              outlined
              placeholder="Identificación del cliente"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="value.name"
              :rules="validation.rules.name"
              label="Nombre"
              outlined
              placeholder="Nombre del cliente"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="value.phone"
              :rules="validation.rules.phone"
              label="Teléfono"
              outlined
              placeholder="Teléfono de contacto"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="value.email"
              :rules="validation.rules.email"
              label="Email"
              outlined
              placeholder="Correo electrónico"
              type="email"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="value.supervisor"
              :items="employees"
              :loading="isLoading"
              :rules="validation.rules.supervisor"
              :search-input.sync="search"
              chips
              hide-details
              hide-selected
              item-text="name"
              item-value="userName"
              label="Supervisor"
              open-on-clear
              outlined
              return-object
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Ingrese el nombre de un
                    <strong>Supervisor</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  class="white--text"
                  close
                  color="#005CAE"
                  v-on="on"
                  @click:close="value.supervisor = null"
                >
                  <span v-text="item.name"/>
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item === 'object'">
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-text="data.item.name"/>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                       <span v-text="data.item.email"/>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <span v-text="data.item"/>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn outlined @click="$router.go(-1)">
        Cancelar
      </v-btn>
      <v-spacer/>
      <v-btn color="primary"
             @click="$refs.form.validate() && $emit('save', value)">
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {EMAIL} from "@/util/regex";
import FormSection from "@/components/widgets/Section"

export default {
  name: "ClientForm",
  components: { FormSection },
  props: {
    title: { type: String, required: true, },
    value: { type: Object, required: true, },
  },
  data: function () {
    return {
      isLoading: false,
      search: null,
      validation: {
        valid: false,
        rules: {
          identification: [v => !!v || "Ingrese la identificación del cliente"],
          name: [v => !!v || "Ingrese el nombre del cliente"],
          supervisor: [v => !!v || "Ingrese un supervisor"],
          phone: [v => !!v || "Ingrese un teléfono de contacto"],
          email: [
            v => !!v || "Ingrese un correo electrónico",
            v => EMAIL.test(v) || "Ingrese un correo electrónico válido",
          ],
        }
      }
    };
  },
  computed: {
    ...mapState("employee", ["employees"]),
  },
  watch: {
    search(val) {
      if (!val) return;

      (this.value.supervisor === null
        || (this.value.supervisor && val !== this.value.supervisor.userName))
      && this.fetchEmployeesDebounced(val);
    },
  },
  methods: {
    ...mapActions("employee", ["fetchEmployees"]),
    fetchEmployeesDebounced(val) {
      this.isLoading = true;
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(
        () => this.doFetchEmployees(val),
        500
      ); /* 500ms throttle */
    },
    doFetchEmployees(val) {
      this.fetchEmployees({ query: val })
        .catch(() => {
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style scoped></style>
