<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col cols="12" md="8" sm="10">
        <client-form v-model="formData" title="Nuevo Cliente" @save="handleCreateItem"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ClientForm from "@/components/forms/ClientForm";
import EventBus from "@/util/eventbus";
import {mapActions} from "vuex";

export default {
  name: "CreateClient",
  components: { ClientForm },
  data: () => ({
    formData: {
      identification: null,
      name: null,
      phone: null,
      supervisor: null,
      description: null,
      officeName: null,
      officeAddress: null,
    }
  }),
  methods: {
    ...mapActions("client", ['createEmployee', 'editEmployee']),
    handleCreateItem(data) {
      this.createEmployee(data)
        .then(() => {
          this.$router.push({ name: 'admin.client.list' })
          EventBus.$emit('SHOW_SNACKBAR', {
            text: 'El cliente ha sido creado',
            color: 'success'
          })
        })
    },
  }
}
</script>

<style scoped>

</style>